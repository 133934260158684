import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import Page from '../components/page'
import H2 from '../components/h2'
import H3 from '../components/h3'
import Button from '../components/button'
import IntroSection from '../components/introSection'
import Footer from '../components/footer'

const PageTransitionStates = posed.div({
  startState: {
    opacity: 1,
    y: 0,
  },
  exitedState: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
    },
  },
})

const OverlayTransitionStates = {
  startState: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exitedState: {
    opacity: 1,
    delay: 300,
    applyAtStart: { display: 'block' },
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: {
      duration: 300,
    },
  },
}

const headerTransitionStates = {
  // startState: {
  //   x: 0,
  //   transition: {
  //     type: 'spring',
  //     stiffness: 200,
  //     damping: 50,
  //   },
  // },
  // exitedState: {
  //   x: 0,
  //   transition: {
  //     type: 'spring',
  //     stiffness: 200,
  //     damping: 50,
  //   },
  // },
  // visible: {
  //   x: 0,
  //   transition: {
  //     type: 'spring',
  //     stiffness: 200,
  //     damping: 50,
  //   },
  // },
}

const TransitionOverlay = styled(posed.div(OverlayTransitionStates))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f6f6;
  opacity: 1;
`

const Header = styled(posed.div(headerTransitionStates))`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    margin: 0 1rem 3rem;
    height: 140px;
  }
`

const BookshelfPadding = styled.div`
  padding: 0 1.55rem 0rem;
  @media (max-width: 768px) {
    padding: 0 2rem 1rem;
  }
`

const ContentGridItem = styled.a`
  display: block;
  width: 162px;
  height: 242px;
  margin-right: 1.5rem;
  min-width: 162px;
  position: relative;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-out;
  transform: scale(1);
  opacity: 1;
  :after {
    content: '';
    position: absolute;
    right: -4px;
    top: 5px;
    bottom: 5px;
    width: 4px;
    background-color: rgba(250, 250, 250, 0.85);
  }
  /* :hover {
    transform: scale(1.03);
    opacity: 0.8;
    cursor: pointer;
  } */
  @media (max-width: 768px) {
    width: 187px;
    height: 280px;
    min-width: 187px;
    scroll-snap-align: start;
  }
  @media (min-width: 768px) {
    :hover {
      transform: scale(1.03);
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

const ContentGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(90deg, rgb(246, 247, 248) 12px, transparent 1%)
      center,
    linear-gradient(rgb(246, 247, 248) 12px, transparent 1%) center, #d8d8d8;
  background-size: 15px 15px;
  padding: 2rem 0;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    scroll-snap-type: both mandatory;
    scroll-padding: 1.5rem;
  }
`

const ContentRow = styled.div`
  margin: 3rem 0 3rem;
  position: relative;
  /*box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
  background: rgb(255, 255, 255);
  padding: 3rem 0 3rem 3rem; */
  ::-webkit-scrollbar {
    display: none;
  }
`

const ContentPage = styled.div`
  margin: 10.55rem 0 0;
  padding: 1.45rem 0 0;
  @media (max-width: 768px) {
    margin: 5rem 0 0;
  }
`

const H3Padded = styled.div`
  max-width: 1400px;
  margin: 2rem 20% 0;
  @media (max-width: 768px) {
    margin: 0 2rem;
  }
`

const ScrollPadding = styled.div`
  min-width: 20%;
  height: 242px;
  background-color: transparent;
  @media (max-width: 768px) {
    min-width: 5%;
    height: 210px;
  }
`

const ImageContainer = styled(Img)`
  width: 100%;
  height: 100%;
  background-color: #d5d5d5;
`

const TitleContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 2rem;
  }
`

const IntroText = styled.p`
  color: #555555;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  max-width: 600px;
  line-height: 1.5em;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  a,
  a:visited {
    color: #555555;
    background-image: linear-gradient(
      to bottom,
      transparent 65%,
      rgba(50, 50, 50, 0.25) 0% /* rgba(35, 86, 155, 0.75) 10% */
    );
    background-repeat: no-repeat;
    text-decoration: none;
    transition: background-position 0.2s ease;
    background-size: 100% 250%;
    background-position: 0% 65%;
    :hover {
      background-position: 0% 100%;
      cursor: pointer;
    }
  }
`

function getTransitionStates(status) {
  if (['entering', 'entered'].includes(status)) {
    return 'visible'
  } else if (['exiting'].includes(status)) {
    return 'exitedState'
  } else if (['exited'].includes(status)) {
    return 'startState'
  } else if (['POP'].includes(status)) {
    return 'visible'
  }
}

// ADD GRADIENT OVERLAY

const PageFour = props => (
  <Page>
    <ContentPage>
      <TransitionState>
        {({ transitionStatus: status }) => {
          return (
            <PageTransitionStates pose={getTransitionStates(status)}>
              <Header>
                <TitleContainer>
                  <H2>Bookshelf</H2>
                </TitleContainer>
                <Button
                  onPressed={() => {
                    window.open(
                      'https://www.goodreads.com/user/show/51464402-oscar-nilsson'
                    )
                  }}
                  showArrow={true}
                >
                  Goodreads
                </Button>
              </Header>
              <BookshelfPadding>
                <IntroSection>
                  <IntroText>Some of my favorite books.</IntroText>
                  <IntroText>
                    Most of my reading gravitates to topics of design, ideas
                    about the future and how to wrestle the human mind into
                    being happier and more mindful.
                  </IntroText>
                </IntroSection>
              </BookshelfPadding>
              <ContentRow>
                <H3Padded>
                  <H3>Non-fiction</H3>
                </H3Padded>
                <ContentGrid>
                  <ScrollPadding />
                  <ContentGridItem href="https://www.goodreads.com/book/show/2034402.Designing_Design">
                    <ImageContainer
                      fluid={props.data.dd.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/41641923-the-courage-to-be-disliked">
                    <ImageContainer
                      fluid={props.data.tctbd.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/37638098-creative-selection">
                    <ImageContainer
                      fluid={props.data.cs.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/40121378-atomic-habits">
                    <ImageContainer
                      fluid={props.data.ah.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/29939161-radical-candor">
                    <ImageContainer
                      fluid={props.data.rc.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/42190.Wabi_Sabi">
                    <ImageContainer
                      fluid={props.data.ws.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/13330588-happy-city">
                    <ImageContainer
                      fluid={props.data.hc.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/23692271-sapiens">
                    <ImageContainer
                      fluid={props.data.sabhoh.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/30659.Meditations">
                    <ImageContainer
                      fluid={props.data.mam.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/18077903-creativity-inc">
                    <ImageContainer
                      fluid={props.data.ci.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ScrollPadding />
                </ContentGrid>
              </ContentRow>

              <ContentRow>
                <H3Padded>
                  <H3>Fiction</H3>
                </H3Padded>
                <ContentGrid>
                  <ScrollPadding />
                  <ContentGridItem href="https://www.goodreads.com/book/show/24885533-the-paper-menagerie-and-other-stories">
                    <ImageContainer
                      fluid={props.data.pm.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/22320.Pattern_Recognition">
                    <ImageContainer
                      fluid={props.data.pr.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/18007564-the-martian">
                    <ImageContainer
                      fluid={props.data.tm.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ContentGridItem href="https://www.goodreads.com/book/show/1971304.City_of_Thieves">
                    <ImageContainer
                      fluid={props.data.cot.childImageSharp.fluid}
                    />
                  </ContentGridItem>
                  <ScrollPadding />
                </ContentGrid>
              </ContentRow>
              <TransitionOverlay pose={getTransitionStates(status)} />
            </PageTransitionStates>
          )
        }}
      </TransitionState>
      <Footer />
    </ContentPage>
  </Page>
)

export default PageFour

export const pageQuery = graphql`
  query {
    dd: file(relativePath: { eq: "books/non-fiction/dd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    tctbd: file(relativePath: { eq: "books/non-fiction/tctbd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    cs: file(relativePath: { eq: "books/non-fiction/cs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ah: file(relativePath: { eq: "books/non-fiction/ah.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rc: file(relativePath: { eq: "books/non-fiction/rc.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ws: file(relativePath: { eq: "books/non-fiction/ws.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hc: file(relativePath: { eq: "books/non-fiction/hc.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    sabhoh: file(relativePath: { eq: "books/non-fiction/sabhoh.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mam: file(relativePath: { eq: "books/non-fiction/mam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ci: file(relativePath: { eq: "books/non-fiction/ci.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    pm: file(relativePath: { eq: "books/fiction/pm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    pr: file(relativePath: { eq: "books/fiction/pr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    tm: file(relativePath: { eq: "books/fiction/tm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    cot: file(relativePath: { eq: "books/fiction/cot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 162) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
